#wrapper {
   
    display: flex;
    width: 100%;
  }
  
  #left {
    flex: 2;
  }
  
  #right {
    flex: 1;
  }