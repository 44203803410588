div#prTabs  {
    border-left: 1px solid #e0e0e0;
}

div#prTabs div.dx-form {
    padding: 10px;
}

.booked {
    background-color: #9C27B0;
    color: white;
}

.booked .dx-icon-clearsquare::before {
    color: white;
}

.booked .dx-icon-preferences::before {
    color: white;
}

.aog {
    background-color: red; 
    color: white;
}
