.App {
  height: 100%;
}

.topbar .dx-toolbar {
  background-color: rgba(191, 191, 191, .15);
  padding: 5px 10px;
}

.topbar .dx-toolbar .dx-toolbar-items-container {
  height: 45px;
}

.ruleBad {
  color: red;
}

.ruleGood {
  color: green;
}
