.currentQuote {
    background-color: #00aa00;
    color: white;
}

.currentQuote .dx-icon-edit::before {
    color: white;
}

.currentQuote .dx-icon-trash::before {
    color: white;
}